<template>
  <div>
    <b-card-title class="mt-2">
      Documentos de errores
    </b-card-title>
    <b-button
      class="mt-1"
      variant="relief-success"
      block
      @click="$emit('exportarErrores')"
    >
      Descargar errorData{{ modulo }}
    </b-button>
    <app-collapse>
      <app-collapse-item :title="`errorData${modulo}.xlsx`">
        En el documento errorData{{modulo}}.xlsx, encontrará un listado con todos los {{ modulo }} que no se cargaron correctamente (Clientes no cargados).
      </app-collapse-item>
    </app-collapse>
    <b-button
      class="mt-1"
      variant="relief-success"
      block
      @click="$emit('exportarLog')"
    >
      Descargar errorLog{{ modulo }}
    </b-button>
    <app-collapse>
      <app-collapse-item :title="`errorLog${modulo}.txt`">
        En el documento errorLog{{modulo}}.txt, encontrará todos los errores ubicados en el documento errorData{{modulo}}.xlsx
      </app-collapse-item>
    </app-collapse>
  </div>
</template>
<script>
import {
  BCardTitle, BButton,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    BCardTitle,
    BButton,

    AppCollapse,
    AppCollapseItem,
  },
  props: {
    modulo: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>
